'use client'

import styles from './Nav.module.scss'
import { useGlobalState } from '@/providers/providers'
import { ClassNames } from '@/services/classname.service'
import { ProjectData } from '@/data/projects'
import Link from 'next/link'
import shortid from 'shortid'

const Nav = ({ className = '' }) => {
  const { globalState, toggleNav, closeNav } = useGlobalState()
  return (
    <nav className={ClassNames([styles.nav, globalState.navOpen ? styles['nav--open'] : null])}>
      <button className={styles['nav__close']} onClick={closeNav}>
        close
      </button>

      <div className={styles['nav__content']}>
        <div className={styles['nav__content__cell']}>
          <h2>Projects</h2>
          <ul>
            {ProjectData.projects.map((project) => {
              return (
                <li key={shortid.generate()}>
                  <Link href={`/projects/${project.id}`} passHref={true}>
                    <span className="strike">{project.title}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Nav
