'use client'

import React from 'react'
import Head from 'next/head'
import styles from '@/styles/Home.module.css'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { useTimeOfDay } from '@/hooks/useTimeOfDay'
import Nav from '@/components/Nav'

const PageLayout = ({ className = '', children, title = '' }) => {
  useTimeOfDay()
  return (
    <div className={styles.container}>
      <Head>
        <title>{title} | Lackner//Buckingham</title>
        <link rel="icon" type="image/svg+xml" href="/squiggles.svg" />
      </Head>

      <Header />

      <Nav />

      <main className={styles.main}>{children}</main>

      <Footer />
    </div>
  )
}

export default PageLayout
