import { useEffect, useState } from 'react'
import useInterval from './useInterval'

const pad = (num) => (num < 10 ? `0${num}` : num)

export const useTimeOfDay = () => {
  useInterval(() => {
    const body = document.querySelector('body')
    const date = new Date()
    body.style.setProperty('--dynamic-background', `rgba(${date.getHours()}, ${date.getMinutes()}, ${date.getSeconds()})`)
  }, 5000)
}