export const ProjectData = {
  projects: [
    {
      title: 'StorySnap',
      gridImage: '/storysnap-thumbs-md.jpg',
      featuredImage: '/ss-hero-neu-1-lg.jpg',
      category: 'iOS/ANDROID',
      skills: 'Strategy UI/UX Swift Node.js Mongodb Javascript',
      location: 'Switzerland | UK',
      shortDescription: 'Storytelling App',
      examples: ['/storysnap-ex-1-lg.jpg', '/storysnap-ex-2-lg.jpg', '/storysnnap-h2-1-lg.jpg'],
      id: 'story-snap',
      tagline: 'Sharing personalized stories with anyone anywhere',
      content:
        "StorySnap app: the superbly simple and user-friendly way to create and share children's video and audiobooks told by you and others you love from wherever you are, so that your voices can tell stories that will be loved and treasured forever, even when you can not."
    },
    {
      title: 'Acid House Kings',
      gridImage: '/ahk-thumb-md.jpg',
      featuredImage: '/ahk-hero-neu-lg.jpg',
      category: 'iOS',
      skills: 'Strategy UI/UX Objective-C Audio Processing ',
      location: 'Sweden',
      shortDescription: 'iPad Album',
      examples: [
        '/ahk-main1-lg.jpg',
        '/ahk-main2-lg.jpg',
        '/ahk-main3-lg.jpg',
        '/ahk-four1-lg.jpg'
      ],
      id: 'acid-house-kings',
      tagline: 'The first enhanced musical experience for iPad',
      content:
        'Released in 2011, this is still one of our very favorite projects. LacknerBuckingham collaborated with Labrador Records, and the Acid House Kings to produce an enhanced musical experience for the iPad, "Music Sounds Better With You". We remember the days when bringing home the latest record from our favorite band had a real sense of discovery. The cover, liner notes, posters, postcards -- you never knew what was going to be inside when you peeled off the cellophane. Our goal for this immersive, interactive album experience is to bring back that same feeling of discovery, using the latest combination of interactive technology, CD quality audio and unique mobility that only the iPad has to offer. Plug in, sit back and enjoy the music. It sounds better with you. Anywhere.'
    },
    {
      title: 'Cervo Rosso Switzerland',
      gridImage: '/cr_thumb-again-md.jpg',
      featuredImage: '/cr-hero-neu-lg.jpg',
      category: 'www',
      skills: 'UI/UX/IA, PHP, MySQL, eCommerce, Branding, Illustration, Video',
      location: 'Switzerland | Australia | Japan',
      shortDescription: 'Cycling Platform',
      examples: ['/cr-home1-best-lg.jpg', '/cr-shop-1-lg.jpg', '/cervo_rosso_vetta-lg.jpg'],
      id: 'cervo-rosso',
      tagline: 'Fully bespoke eCommerce platform',
      content:
        'LacknerBuckingham created a fully bespoke, comprehensive eCommerce site for Cervo Rosso Switzerland. The site includes a fully customized checkout process with smart cues that greatly enhances the UX over stock cart solutions. In addition we added an industry first, self-publishing review feature -- Follow The Peloton -- that allows users to register and review their new products via a narrative platform. Sprinkled throughout the site are custom branded icons an identity design. The overall effect reflects Cervo Rosso great respect for their customers and the founding principle that "It\'s Your Ride." Contact us to find out more.'
    },
    {
      title: 'Nørrona Magazine',
      gridImage: '/norrona-thumb-lg.jpg',
      featuredImage: '/norrona-hero-neu-lg.jpg',
      category: 'iOS',
      skills: 'Strategy UI/UX/UI Animation Objective-C Animation',
      location: 'Norway',
      shortDescription: 'iPad Magazine',
      examples: ['/norrona-main-1-lg.jpg'],
      id: 'norrona-magazine',
      tagline: 'Bespoke dual language immersion',
      content: `LacknerBuckingham was commissioned along with our partners Sletten & Østvold to produce an iPad version of Nørrona Magazine for Apple's Newsstand. The app was a bilingual, digital version of the popular print publication. The experience had many unique functions including interactive immersive ads, and bonus images and video that enhanced the experience and improved the brand image.

Reached #1 on the Apple App Store Norway.

Reached #1 on the Apple App Store Lifestyle charts. Top 100 in 3 countries.`
    },
    {
      title: 'Montour',
      gridImage: '/montour-new-thumb-2-md.jpg',
      featuredImage: '/montour-hero-neu-3-lg.jpg',
      category: 'iOS',
      skills: 'Branding Strategy UI/UX Objective-C',
      location: 'Madison, WI',
      shortDescription: 'Cycling App',
      examples: ['/montour-lg.jpg', '/montour-icons-lg.jpg'],
      id: 'montour',
      tagline: 'Solo cycling app',
      content: `Montour is a first of it's kind cycling app especially targeted for solo and small group riders. Besides the ability to track your ride, you can check weather conditions, add music playlists and share photos and stories. Most importantly riders can be tracked and send for help to designated individuals in case of a flat or accident.`
    },
    {
      title: "The Cook's Atelier",
      gridImage: '/cooks-thumb-md.jpg',
      featuredImage: '/cooks-hero-neu-lg.jpg',
      category: 'www',
      skills: 'Strategy UI/UX/IA PHP MySQL Branding',
      location: 'France',
      shortDescription: 'Stories and Storefront',
      examples: ['/cooks-main1-lg.jpg', '/cooks-shop-1-lg.jpg'],
      id: 'the-cooks-atelier',
      tagline: 'A French cooking school and storefront',
      content: `The Cook’s Atelier is an epicurean center in the center of historic Beaune, France. They have collaborated with their friends at AP Wine Beaune to create a destination for food and wine lovers that highlights the local artisan food producers and winemakers of Burgundy and throughout Europe. Their epicurean center is housed in a 17th century building and includes a wine shop, a retail space, our teaching kitchen and dining room. The Cook’s Atelier is a place for locals and visitors alike to come and participate in special events around local food and wine. LacknerBuckingham produced a unique site with a distinct narrative for the Cook's Atelier, which will also included their bespoke storefront in 2014. Contact us to find out more.`
    },
    {
      title: 'Sweet Paul Holiday Kids',
      gridImage: '/sweetpaul-thumb-md.jpg',
      featuredImage: '/sp-hero-neu-lg.jpg',
      category: 'iOS',
      skills: 'Strategy UI/UX/IA Animation Objective-C Animation',
      location: 'New York NY',
      shortDescription: 'Food & Crafts',
      examples: ['/sweetpaul-home-1-lg.jpg'],
      id: 'sweet-paul-holiday-kids',
      tagline: 'Enchanting holiday interactivity',
      content: `Sweet Paul’s 2013 Holiday Kids Issue presented by Johnson & Johnson. Paul “Sweet Paul” Lowe is guided by his grandmother, Mormor’s, motto: “fullkommenhet er kjedelig” -- “perfection is boring”. Born in Oslo, his Mormor and Great Auntie Gunnvor instilled in him a love for cooking and crafting that carried over to his career in New York as a food and props stylist, and eventually the creative guru behind the quarterly Sweet Paul Magazine. Flipping through the pages of his eponymous magazine, you’ll be riveted by beautiful culinary and crafting ideas that remain humble and accessible -- but never boring!`
    },
    {
      title: 'So It Goes',
      gridImage: '/sig-thumb-md.jpg',
      featuredImage: '/sig-hero-neu-lg.jpg',
      category: 'iOS',
      skills: 'Strategy UI/UX/IA Objective-C',
      location: 'London UK',
      shortDescription: 'iPad Magazine',
      examples: ['/sig-cover-2-lg.jpg', '/sig-home-1-lg.jpg'],
      id: 'so-it-goes',
      tagline: 'An elevated interactive cultural experience',
      content: `We partnered with So It Goes in London to bring their print publication to the iPad. The trendy culture magazine was brought to life with engaging and immersive features that only a custom native app can achieve. Supplementary video, clever use of gyroscopic page animation as well as tons of bonus content and outtakes highlighted the experience. A hallmark of the LacknerBuckingham digital experience were custom interactive ads. Who new advertising could be this much fun and useful?`
    },
    {
      title: 'Pawzr',
      gridImage: '/pawzr-thumb-2-md.jpg',
      featuredImage: '/pawzr-hero-neu-lg.jpg',
      category: 'iOS / ANDROID / WWW',
      skills: 'Branding Strategy UI/UX/IA Swift Mongodb Javascript',
      location: 'Chicago, IL',
      shortDescription: 'Pet Platform',
      examples: ['/pawzr-home-2-lg.jpg'],
      id: 'pawzr',
      tagline: 'A platform for pets and their owners',
      content: `Launching Summer 2018 Pawzr is a human platform centered on pets and their services. Users can organize all their pet's data in a single place. In addition they can seek out pet-friendly businesses word-wide.`
    },
    {
      title: 'Mosaiscope',
      gridImage: '/mosaiscope-thumb2-md.jpg',
      featuredImage: '/mosiascope-hero-neu-lg.jpg',
      category: 'iOS / WWW',
      skills: 'Strategy UI/UX/IA Node.js Swift Neo4j Javascript Animation',
      location: 'Portland OR',
      shortDescription: 'RSS News Reader',
      examples: ['/mosaiscope-feature-lg.jpg', '/mosaiscope-2-lg.jpg'],
      id: 'mosaiscope',
      tagline: "Creating the world's best news reader",
      content: `Mosaiscope is a flexible RSS news aggregator and reader. Whether you are using our web-app or native iOS app you can follow all your favorite websites with an RSS feed in one place. You can also save articles and add them to your own curated collections. Also in-app is a patent pending Pinpoint Bookmarking feature allowing letter-level-on-a-page bookmarking.

      "If Feedly and Flipboard aren’t doing it for you, try out Mosaiscope, a sleek newcomer to the RSS aggregator field."

      _Gear Patrol`
    },
    {
      title: 'Kinfolk Magazine',
      gridImage: '/kinolk-thumb1-md.jpg',
      featuredImage: '/kf-hero-neu-lg.jpg',
      category: 'iOS',
      skills: 'Strategy UI/UX Objective-C',
      location: 'Portland OR | Denmark',
      shortDescription: 'iPad Magazine',
      examples: ['/kinfolk-main-1-1-lg.jpg', '/kinfolk-main-1-lg.jpg', '/kinfolk-main-2-lg.jpg'],
      id: 'kinfolk-magazine',
      tagline: 'Bespoke indie publishing platform',
      content: `We worked with independent publishing vanguard Kinfolk Magazine at the very beginning to bring their publication as a digital exclusive to the iPad. In 2011 it was the very first fully bespoke, fully interactive, designed specifically for the iPad, independent publication brought to the iPad. We produced Vol 2, 3 and 4 before they moved to a web based subscription model.

      Kinfolk Volume 4 reached #1 on the Apple App Store in 14 countries. `
    },
    {
      title: 'Edible Recipe Guide',
      gridImage: '/edible-thumb-md.jpg',
      featuredImage: '/edible-hero-neu-lg.jpg',
      category: 'iOS',
      skills: 'Strategy UI/UX/IA Objective-C',
      location: 'Ojai CA',
      shortDescription: 'Recipe App',
      examples: ['/edible-hero-1-lg.jpg', '/edible-hero-2-lg.jpg'],
      id: 'edible-recipe-guide',
      tagline: 'Recipes from the edible community',
      content: `We worked with Edible Communities to bring their recipe guide to the Apple App Store. In this delicious new app, we present the very best of Edible Communities recipes --- a must-have collection of local, sustainable dishes that feature delectable meals to warm and wow, from luscious soups to divine desserts and everything in between.`
    },
    {
      title: 'Custom ETicketing',
      gridImage: '/ducks-thumb-2-md.jpg',
      featuredImage: '/ducks-hero-neu-lg.jpg',
      category: 'iOS',
      skills: 'Strategy UI/UX Objective-C PHP MySQL',
      location: 'Wisconsin Dells WI',
      shortDescription: 'iPad Event Platform',
      examples: ['/ducks-main-2-lg.jpg'],
      id: 'custom-eticketing',
      tagline: 'Inovation eTicketing platform',
      content: `We created a fully bespoke eTicketing solution utilizing the iPad Mini as a scanning device. It's low cost and high quality camera was the perfect vehicle to build the app around. As far as we know it was the first real-time QR ticketing system of it's kind in the world, definitely the only fully bespoke solution. Users purchase their etickets online for a variety of attractions. Each QR code includes all the data from their purchase and allows the dispatcher to scan all the users on a ticket, or just certain individuals. The ticket can be presented on paper, or via smartphone. These scans are recorded in real-time at the main office, so sales and usage can be monitored by boat, day, or month etc. Super simple, super powerful. After a full season of use this platform increased eticketing sales by 2000% (not a typo) over their previous eticketing system.`
    }
  ]
}
