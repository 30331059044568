import styles from './Footer.module.scss'

const Footer = ({ className = '' }) => {
  return (
    <footer className={styles.footer}>
      <div className="text">
        <img src="/squiggles.svg" width="30" className={styles.squiggles} />
        <p className="my-10">Made Somewhere in Wisconsin</p>
        <div className="logos">
          <img src="/footer.jpg" />
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
